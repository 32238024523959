import { render, staticRenderFns } from "./default.vue?vue&type=template&id=e9a48608&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=e9a48608&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsApp: require('/opt/components/icons/App.vue').default,ManageOrganization: require('/opt/components/ManageOrganization.vue').default,HeaderNavigation: require('/opt/components/header/HeaderNavigation.vue').default,HeaderNavigationMobile: require('/opt/components/header/HeaderNavigationMobile.vue').default,Footer: require('/opt/components/Footer.vue').default,Notifications: require('/opt/components/Notifications.vue').default})
