
import LanguageSelector from '../LanguageSelector.vue'
import ManageInvitations from '../ManageInvitations.vue'
export default {
  components: {
    LanguageSelector,
    ManageInvitations
  },
  middleware: ['auth'],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      filter: '',
      notification: false,
      smallLayout: false,
      showSidePanel: false,
      showLanguageSelector: false
    }
  },
  computed: {
    connectUrl () {
      return this.$config.connectUrl + '/profile/'
    },
    platformUrl () {
      return this.$config.platformUrl
    },
    userName () {
      if (this.$auth.user) {
        return `${this.$auth.user.given_name} ${this.$auth.user.family_name}`
      } else {
        return 'NN'
      }
    },
    email () {
      if (this.$auth.user) {
        return this.$auth.user.email
      } else {
        return 'NN'
      }
    },
    isConnectedAndSmallLayout () {
      if (this.$auth.loggedIn && this.smallLayout) {
        return false
      }
      return true
    }
  },
  created () {
    if (process.client) {
      // eslint-disable-next-line
      window.vm = this.$root;
    }
  },
  methods: {
    onLogoutClick () {
      this.$auth.logout()
    },
    onMenuClick () {
      this.showSidePanel = !this.showSidePanel
    },
    closeLanguageSelector () {
      this.showLanguageSelector = false
    },
    handleSearch (e) {
      this.$store.dispatch('filterSearch', e)
    },
    goToApplicationPage (application) {
      this.$router.push(
        this.localePath({
          path: `/application/${application.id}`
        })
      )
    },
    goToApplicationPageWithEnter (applicationId) {
      this.$router.push(
        this.localePath({
          path: `/application/${applicationId}`
        })
      )
    },
    goToAllResultsPage (event) {
      this.$store.dispatch('filterResults', event.results)
      this.$router.push(
        this.localeRoute({
          name: 'search',
          params: {
            results: event.results
          },
          query: {
            slug: event.search
          }
        })
      )
    }
  }
}
