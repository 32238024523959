import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a018322c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _799b8a01 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3de74e51 = () => interopDefault(import('../pages/oidc-callback.vue' /* webpackChunkName: "pages/oidc-callback" */))
const _32e72d68 = () => interopDefault(import('../pages/dev-corner/index.vue' /* webpackChunkName: "pages/dev-corner/index" */))
const _92fe15d2 = () => interopDefault(import('../pages/dropbox/index.vue' /* webpackChunkName: "pages/dropbox/index" */))
const _85e27d9e = () => interopDefault(import('../pages/invitation.vue' /* webpackChunkName: "pages/invitation" */))
const _a5e869cc = () => interopDefault(import('../pages/my-organizations/index.vue' /* webpackChunkName: "pages/my-organizations/index" */))
const _592cf37a = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _3d54b751 = () => interopDefault(import('../pages/dev-corner/api-access.vue' /* webpackChunkName: "pages/dev-corner/api-access" */))
const _2b13abe4 = () => interopDefault(import('../pages/dev-corner/marketplace-application.vue' /* webpackChunkName: "pages/dev-corner/marketplace-application" */))
const _ce3831ac = () => interopDefault(import('../pages/dev-corner/your-api-access/index.vue' /* webpackChunkName: "pages/dev-corner/your-api-access/index" */))
const _72427fac = () => interopDefault(import('../pages/my-organizations/create-organization.vue' /* webpackChunkName: "pages/my-organizations/create-organization" */))
const _3c8d75cb = () => interopDefault(import('../pages/my-organizations/success.vue' /* webpackChunkName: "pages/my-organizations/success" */))
const _c07d45d6 = () => interopDefault(import('../pages/dev-corner/your-api-access/_id/index.vue' /* webpackChunkName: "pages/dev-corner/your-api-access/_id/index" */))
const _635d21c9 = () => interopDefault(import('../pages/my-organizations/organization/_id/index.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/index" */))
const _1606799c = () => interopDefault(import('../pages/my-organizations/organization/_id/billing.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/billing" */))
const _44e827d0 = () => interopDefault(import('../pages/my-organizations/organization/_id/create-application.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/create-application" */))
const _47b3d8fe = () => interopDefault(import('../pages/my-organizations/organization/_id/create-invitation.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/create-invitation" */))
const _e689794a = () => interopDefault(import('../pages/my-organizations/organization/_id/application/applications.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/application/applications" */))
const _d7bdd6f4 = () => interopDefault(import('../pages/my-organizations/organization/_id/settings/settings.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/settings/settings" */))
const _2ba426a2 = () => interopDefault(import('../pages/my-organizations/organization/_id/space/spaces.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/space/spaces" */))
const _42ea2178 = () => interopDefault(import('../pages/my-organizations/organization/_id/users/users.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/users/users" */))
const _3683ab3e = () => interopDefault(import('../pages/my-organizations/organization/_id/users/user-actions/UserActions.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/users/user-actions/UserActions" */))
const _57c426f2 = () => interopDefault(import('../pages/my-organizations/organization/_id/application/_applicationid/deactivate-application.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/application/_applicationid/deactivate-application" */))
const _3923eec7 = () => interopDefault(import('../pages/my-organizations/organization/_id/application/_applicationid/update-application.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/application/_applicationid/update-application" */))
const _252d51fd = () => interopDefault(import('../pages/my-organizations/organization/_id/space/_spaceid/update-space.vue' /* webpackChunkName: "pages/my-organizations/organization/_id/space/_spaceid/update-space" */))
const _11ad8b54 = () => interopDefault(import('../pages/application/_id/index.vue' /* webpackChunkName: "pages/application/_id/index" */))
const _154d57d8 = () => interopDefault(import('../pages/application/_id/activated-application.vue' /* webpackChunkName: "pages/application/_id/activated-application" */))
const _3900ba8e = () => interopDefault(import('../pages/application/_id/success-activated-application.vue' /* webpackChunkName: "pages/application/_id/success-activated-application" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _a018322c,
    name: "index___en"
  }, {
    path: "/fr",
    component: _a018322c,
    name: "index___fr"
  }, {
    path: "/login",
    component: _799b8a01,
    name: "login"
  }, {
    path: "/oidc-callback",
    component: _3de74e51,
    name: "oidc-callback"
  }, {
    path: "/en/dev-corner",
    component: _32e72d68,
    name: "dev-corner___en"
  }, {
    path: "/en/dropbox",
    component: _92fe15d2,
    name: "dropbox___en"
  }, {
    path: "/en/invitation",
    component: _85e27d9e,
    name: "invitation___en"
  }, {
    path: "/en/my-organizations",
    component: _a5e869cc,
    name: "my-organizations___en"
  }, {
    path: "/en/search",
    component: _592cf37a,
    name: "search___en"
  }, {
    path: "/fr/dev-corner",
    component: _32e72d68,
    name: "dev-corner___fr"
  }, {
    path: "/fr/dropbox",
    component: _92fe15d2,
    name: "dropbox___fr"
  }, {
    path: "/fr/invitation",
    component: _85e27d9e,
    name: "invitation___fr"
  }, {
    path: "/fr/my-organizations",
    component: _a5e869cc,
    name: "my-organizations___fr"
  }, {
    path: "/fr/search",
    component: _592cf37a,
    name: "search___fr"
  }, {
    path: "/en/dev-corner/api-access",
    component: _3d54b751,
    name: "dev-corner-api-access___en"
  }, {
    path: "/en/dev-corner/marketplace-application",
    component: _2b13abe4,
    name: "dev-corner-marketplace-application___en"
  }, {
    path: "/en/dev-corner/your-api-access",
    component: _ce3831ac,
    name: "dev-corner-your-api-access___en"
  }, {
    path: "/en/my-organizations/create-organization",
    component: _72427fac,
    name: "my-organizations-create-organization___en"
  }, {
    path: "/en/my-organizations/success",
    component: _3c8d75cb,
    name: "my-organizations-success___en"
  }, {
    path: "/fr/dev-corner/api-access",
    component: _3d54b751,
    name: "dev-corner-api-access___fr"
  }, {
    path: "/fr/dev-corner/marketplace-application",
    component: _2b13abe4,
    name: "dev-corner-marketplace-application___fr"
  }, {
    path: "/fr/dev-corner/your-api-access",
    component: _ce3831ac,
    name: "dev-corner-your-api-access___fr"
  }, {
    path: "/fr/my-organizations/create-organization",
    component: _72427fac,
    name: "my-organizations-create-organization___fr"
  }, {
    path: "/fr/my-organizations/success",
    component: _3c8d75cb,
    name: "my-organizations-success___fr"
  }, {
    path: "/en/dev-corner/your-api-access/:id",
    component: _c07d45d6,
    name: "dev-corner-your-api-access-id___en"
  }, {
    path: "/en/my-organizations/organization/:id",
    component: _635d21c9,
    name: "my-organizations-organization-id___en"
  }, {
    path: "/fr/dev-corner/your-api-access/:id",
    component: _c07d45d6,
    name: "dev-corner-your-api-access-id___fr"
  }, {
    path: "/fr/my-organizations/organization/:id",
    component: _635d21c9,
    name: "my-organizations-organization-id___fr"
  }, {
    path: "/en/my-organizations/organization/:id/billing",
    component: _1606799c,
    name: "my-organizations-organization-id-billing___en"
  }, {
    path: "/en/my-organizations/organization/:id/create-application",
    component: _44e827d0,
    name: "my-organizations-organization-id-create-application___en"
  }, {
    path: "/en/my-organizations/organization/:id/create-invitation",
    component: _47b3d8fe,
    name: "my-organizations-organization-id-create-invitation___en"
  }, {
    path: "/fr/my-organizations/organization/:id/billing",
    component: _1606799c,
    name: "my-organizations-organization-id-billing___fr"
  }, {
    path: "/fr/my-organizations/organization/:id/create-application",
    component: _44e827d0,
    name: "my-organizations-organization-id-create-application___fr"
  }, {
    path: "/fr/my-organizations/organization/:id/create-invitation",
    component: _47b3d8fe,
    name: "my-organizations-organization-id-create-invitation___fr"
  }, {
    path: "/en/my-organizations/organization/:id/application/applications",
    component: _e689794a,
    name: "my-organizations-organization-id-application-applications___en"
  }, {
    path: "/en/my-organizations/organization/:id/settings/settings",
    component: _d7bdd6f4,
    name: "my-organizations-organization-id-settings-settings___en"
  }, {
    path: "/en/my-organizations/organization/:id/space/spaces",
    component: _2ba426a2,
    name: "my-organizations-organization-id-space-spaces___en"
  }, {
    path: "/en/my-organizations/organization/:id/users/users",
    component: _42ea2178,
    name: "my-organizations-organization-id-users-users___en"
  }, {
    path: "/fr/my-organizations/organization/:id/application/applications",
    component: _e689794a,
    name: "my-organizations-organization-id-application-applications___fr"
  }, {
    path: "/fr/my-organizations/organization/:id/settings/settings",
    component: _d7bdd6f4,
    name: "my-organizations-organization-id-settings-settings___fr"
  }, {
    path: "/fr/my-organizations/organization/:id/space/spaces",
    component: _2ba426a2,
    name: "my-organizations-organization-id-space-spaces___fr"
  }, {
    path: "/fr/my-organizations/organization/:id/users/users",
    component: _42ea2178,
    name: "my-organizations-organization-id-users-users___fr"
  }, {
    path: "/en/my-organizations/organization/:id/users/user-actions/UserActions",
    component: _3683ab3e,
    name: "my-organizations-organization-id-users-user-actions-UserActions___en"
  }, {
    path: "/fr/my-organizations/organization/:id/users/user-actions/UserActions",
    component: _3683ab3e,
    name: "my-organizations-organization-id-users-user-actions-UserActions___fr"
  }, {
    path: "/en/my-organizations/organization/:id/application/:applicationid/deactivate-application",
    component: _57c426f2,
    name: "my-organizations-organization-id-application-applicationid-deactivate-application___en"
  }, {
    path: "/en/my-organizations/organization/:id/application/:applicationid/update-application",
    component: _3923eec7,
    name: "my-organizations-organization-id-application-applicationid-update-application___en"
  }, {
    path: "/en/my-organizations/organization/:id/space/:spaceid/update-space",
    component: _252d51fd,
    name: "my-organizations-organization-id-space-spaceid-update-space___en"
  }, {
    path: "/fr/my-organizations/organization/:id/application/:applicationid/deactivate-application",
    component: _57c426f2,
    name: "my-organizations-organization-id-application-applicationid-deactivate-application___fr"
  }, {
    path: "/fr/my-organizations/organization/:id/application/:applicationid/update-application",
    component: _3923eec7,
    name: "my-organizations-organization-id-application-applicationid-update-application___fr"
  }, {
    path: "/fr/my-organizations/organization/:id/space/:spaceid/update-space",
    component: _252d51fd,
    name: "my-organizations-organization-id-space-spaceid-update-space___fr"
  }, {
    path: "/en/application/:id",
    component: _11ad8b54,
    name: "application-id___en"
  }, {
    path: "/fr/application/:id",
    component: _11ad8b54,
    name: "application-id___fr"
  }, {
    path: "/en/application/:id?/activated-application",
    component: _154d57d8,
    name: "application-id-activated-application___en"
  }, {
    path: "/en/application/:id?/success-activated-application",
    component: _3900ba8e,
    name: "application-id-success-activated-application___en"
  }, {
    path: "/fr/application/:id?/activated-application",
    component: _154d57d8,
    name: "application-id-activated-application___fr"
  }, {
    path: "/fr/application/:id?/success-activated-application",
    component: _3900ba8e,
    name: "application-id-success-activated-application___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
